import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"



class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage

    const cleanExcerpt = currentPage.excerpt.replace(/<\/?[^>]+(>|$)/g, "");
    const cleanTitle = currentPage.title.replace(/<\/?[^>]+(>|$)/g, "");

    return (
      <Layout>
        <SEO title={cleanTitle} description={cleanExcerpt} />
        <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
        {/* {currentPage} */}
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
    }
    
  }
`